<template>
  <div class="w-full">
    <SuccessButton
      class="mr-6"
      :disabled="!$atividades.permissoes.includes(14)"
      @func="openModal()"
    ></SuccessButton>
    <SideBar
      title="Cadastrar produto"
      @hide="modalCadastro = false"
      :active="modalCadastro"
      @success="submitForm()"
      size="large"
    >
      <div slot="body">
        <div class="w-full vs-con-loading__container" id="loadingCadastro">
          <vs-row class="mb-4" vs-type="flex" vs-w="12">
            <vs-col vs-w="6" class="px-1">
              <div style="display:inline-block" class=" w-full">
                <vs-input
                  class="inputx w-full"
                  label-placeholder="Nome"
                  v-model="produto.nome"
                  name="nome"
                  v-validate="'required'"
                />
                <span
                  class="ml-1 text-danger"
                  style="font-size: 11px"
                  v-show="errors.has('nome')"
                >
                  {{ $validators.empty }}</span
                >
              </div>
            </vs-col>
            <vs-col vs-w="6" class="px-1">
              <div style="display:inline-block" class=" w-full">
                <vs-input
                  class="inputx w-full"
                  label-placeholder="Sigla"
                  name="sigla"
                  v-validate="'required'"
                  v-model="produto.sigla"
                />
                <span
                  class="ml-1 text-danger"
                  style="font-size: 11px"
                  v-show="errors.has('sigla')"
                >
                  {{ $validators.empty }}</span
                >
              </div>
            </vs-col>
            <vs-col vs-w="6" class="mt-2 px-1">
              <vs-input
                class="inputx w-full"
                label-placeholder="Fabricante"
                v-model="produto.fabricante"
              />
            </vs-col>
            <vs-col vs-w="6" class="mt-2 px-1">
              <vs-input
                class="inputx w-full"
                label-placeholder="CFOP"
                v-model="produto.cfop"
              />
            </vs-col>
            <vs-col vs-w="6" class="mt-2 px-1">
              <vs-input
                class="inputx w-full"
                label-placeholder="CST"
                v-model="produto.cst"
              />
            </vs-col>
            <vs-col vs-w="6" class="mt-2 px-1">
              <vs-input
                class="inputx w-full"
                label-placeholder="NCM"
                v-model="produto.ncm"
              />
            </vs-col>
            <vs-col vs-w="6" class="mt-2 px-1">
              <vs-input
                class="inputx w-full"
                label-placeholder="Grupo Quimico"
                v-model="produto.grupo_quimico"
              />
            </vs-col>
            <vs-col vs-w="6" class="mt-2 px-1">
              <vs-input
                class="inputx w-full"
                label-placeholder="Antidoto / Tratamento"
                v-model="produto.antidoto_tratamento"
              />
            </vs-col>
            <vs-col vs-w="6" class="mt-2 px-1">
              <vs-input
                class="inputx w-full"
                label-placeholder="Acao toxica"
                v-model="produto.acao_toxica"
              />
            </vs-col>
            <vs-col vs-w="6" class="mt-2 px-1">
              <vs-input
                class="inputx w-full"
                label-placeholder="Concentracao"
                v-model="produto.concentracao"
              />
            </vs-col>
            <vs-col vs-w="6" class="mt-2 px-1">
              <vs-input
                class="inputx w-full"
                label-placeholder="Principio Ativo"
                v-model="produto.concentracao_principio_ativo"
              />
            </vs-col>
            <vs-col vs-w="6" class="mt-2 px-1">
              <vs-input
                class="inputx w-full"
                label-placeholder="Diluicao"
                v-model="produto.diluicao"
              />
            </vs-col>
              <vs-col vs-w="5" class="mt-2  px-1">
                <div class="w-full">
                  <label style="color: black; font-size: .85rem;" class="ml-1"
                    >Unid. de Medida</label
                  ><br />
                  <el-select
                    filterable
                    clearable
                    class="w-full"
                    placeholder="Selecione..."
                    name="undMedida"
                    v-validate="'required'"
                    v-model="produto.id_unidade_medida"
                    :popper-append-to-body="false"
                  >
                    <el-option
                      v-for="unidade in unidadeMedida"
                      class="select-info"
                      :value="unidade.id"
                      :label="unidade.nome"
                      :key="unidade.id"
                    >
                    </el-option>
                  </el-select>
                  <vs-row>
                    <span
                      class="ml-1 text-danger"
                      style="font-size: 11px"
                      v-show="errors.has('undMedida')"
                    >
                      {{ $validators.empty }}</span
                    >
                  </vs-row>
                </div>
              </vs-col>
            <vs-col vs-col vs-w="2" vs-justify="center" vs-align="center" vs-type="flex" class="mt-2">
              <div class="mt-10 p-0">
                <vs-checkbox color="success" v-model="produto.isca">ISCA</vs-checkbox>
              </div>
            </vs-col>
            <vs-col vs-w="4.5" vs-justify="center" vs-align="center" vs-type="flex" class="mt-2">
              <vs-input-number
                label="Qtd. Minima"
                style="font-size:14px;width: 70% "
                size="large"
                class="mt-10 p-0"
                :min="1"
                name="qtd"
                v-validate="'numeric'"
                v-model="produto.quantidade_minima"
              />
            </vs-col>
          </vs-row>
        </div>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";
export default {
  data() {
    return {
      produto: { },
      modalCadastro: false,
      unidadeMedida: {},
      error: false
    };
  },
  methods: {
    async openModal() {
      this.produto = {quantidade_minima: 1};
      this.modalCadastro = true;
      try {
        await this.getUnidadeMedida();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async submitForm() {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.cadastrarProduto();
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger"
            });
          }
        });
      });
    },
    async cadastrarProduto() {
      await this.$vs.loading({
        container: "#loadingCadastro",
        scale: 0.6
      });
      try {
        const res = await this.$http.post("produto", this.produto);
        this.$vs.notify(this.$notify.Success);
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "cadastrar",
        //   setor: "comercial",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: "Cadastro do produto N°" + res.id
        // };
        // await this.$logger(logData);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.modalCadastro = await false;
        await this.$emit("update");
        await this.$vs.loading.close("#loadingCadastro > .con-vs-loading");
      }
    },
    async getUnidadeMedida() {
      try {
        this.unidadeMedida = await this.$http.get(`unidade_medida`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    }
  },
  components: components
};
</script>

<style></style>
