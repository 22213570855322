<template>
  <div>
    <a
      href="#"
      @click="
        !$atividades.permissoes.includes(14) || produto.lixeira > 0
          ? ''
          : openModal()
      "
    >
      <feather-icon
        :style="
          !$atividades.permissoes.includes(14) || produto.lixeira > 0
            ? 'color: lightgrey;'
            : 'color: #0f4084'
        "
        icon="SettingsIcon"
        class="mr-2"
      />
    </a>
    <SideBar
      v-if="open"
      title="Gerenciar Métodos do Produto"
      :active="modalGerenciar"
      @hide="cancelar"
      :disabled="!$atividades.permissoes.includes(15)"
      @success="associarMetodosProduto()"
      textSucces="Associar"
      size="small"
      icon="edit"
    >
      <div slot="body">
        <vs-row class="mt-4" vs-type="flex" vs-w="12">
          <vs-col vs-w="12" class="pr-1">
            <div
              :id="'gerenciarMetodos' + produto.id"
              class="vs-con-loading__container w-full"
            >
              <label style="color: black; font-size: .85rem;" class="m-0 pl-3"
                >Métodos</label
              >
              <el-select
                class="w-full"
                v-model="metodosSelected"
                multiple
                clearable
                name="dias"
                :popper-append-to-body="false"
                filterable
              >
                <el-option
                  v-for="metodo in metodos"
                  :key="metodo.id"
                  :label="metodo.nome"
                  :value="metodo.id"
                ></el-option>
              </el-select>
            </div>
          </vs-col>

          <vs-col vs-w="12" class="pr-1 pt-5">
            <vs-list>
              <vs-list-header
                icon=""
                color="dark"
                title="Métodos Associados: "
              ></vs-list-header>
              <VuePerfectScrollbar
                class="scroll-gerenciar-metodos"
                :settings="settings"
              >
                <vs-col
                  vs-w="12"
                  class="pr-1"
                  v-for="item in metodosList"
                  :key="item.id"
                >
                  <vs-list-item
                    vs-list-item
                    icon="check"
                    :title="item.nome"
                    subtitle=""
                  ></vs-list-item>
                </vs-col>
              </VuePerfectScrollbar>
            </vs-list>
          </vs-col>
        </vs-row>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";
export default {
  props: { produto: Object },
  data() {
    return {
      modalGerenciar: false,
      open: false,
      metodosList: [],
      metodosSelected: {},
      metodos: [],
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.6,
        height: 1000
      }
    };
  },
  methods: {
    async openModal() {
      this.metodosSelected = await [];
      this.open = await true;
      this.modalGerenciar = await true;
      try {
        await this.getMetodosAssociar();
        await this.getMetodosByProduto();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getMetodosByProduto() {
      await this.$vs.loading({
        container: "#gerenciarMetodos" + this.produto.id,
        scale: 0.6
      });
      try {
        this.metodosList = await this.$http.get(
          `getMetodosByProduto/` + this.produto.id
        );
        this.metodosList.forEach(async metodosSelected => {
          await this.metodosSelected.push(metodosSelected.id);
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          "#gerenciarMetodos" + this.produto.id + " > .con-vs-loading"
        );
      }
    },
    async associarMetodosProduto() {
      await this.$vs.loading({
        container: "#gerenciarMetodos" + this.produto.id,
        scale: 0.6
      });
      try {
        await this.$http.post("associarMetodosProduto", {
          id: this.produto.id,
          metodos: this.metodosSelected
        });
        await this.$emit("update");
        this.modalGerenciar = await false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          "#gerenciarMetodos" + this.produto.id + " > .con-vs-loading"
        );
      }
    },
    async getMetodosAssociar() {
      try {
        this.metodos = await this.$http.get(`getMetodosAssociar`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    cancelar() {
      this.modalGerenciar = false;
    }
  },
  components: {
    ...components
  }
};
</script>

<style>
.scroll-gerenciar-metodos {
  max-height: 60vh !important;
}
</style>
