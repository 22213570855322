<template>
  <div class="w-full">
    <EditButton
      :disabled="!$atividades.permissoes.includes(14)"
      @func="
        modalEditarProduto = true;
        getProdutoLotes();
      "
    ></EditButton>
    <SideBar
      title="Editar produto"
      @hide="cancelar"
      :active="modalEditarProduto"
      :item="produto"
      :disabled="!$atividades.permissoes.includes(15)"
      @success="submitForm()"
      @excluir="exluirProduto()"
      @status="mudarStatusProduto()"
      textSucces="Atualizar"
      size="large"
      icon="edit"
    >
      <div slot="body">
        <VuePerfectScrollbar class="scroll-cliente-editar" :settings="settings">
          <vs-row class="mb-4" vs-type="flex" vs-w="12">
            <vs-col vs-w="6" class="px-1">
              <div style="display: inline-block" class="w-full">
                <vs-input
                  class="inputx w-full"
                  label-placeholder="Nome"
                  v-model="produto.nome"
                  name="nome"
                  v-validate="'required'"
                />
                <span
                  class="ml-3 text-danger text-sm"
                  v-show="errors.has('nome')"
                >
                  {{ $validators.empty }}</span
                >
              </div>
            </vs-col>
            <vs-col vs-w="6" class="px-1">
              <div style="display: inline-block" class="w-full">
                <vs-input
                  class="inputx w-full"
                  label-placeholder="Sigla"
                  name="sigla"
                  v-validate="'required'"
                  v-model="produto.sigla"
                />
                <span
                  class="ml-3 text-danger text-sm"
                  v-show="errors.has('sigla')"
                >
                  {{ $validators.empty }}</span
                >
              </div>
            </vs-col>
            <vs-col vs-w="6" class="mt-2 px-1">
              <vs-input
                class="inputx w-full"
                label-placeholder="Fabricante"
                v-model="produto.fabricante"
              />
            </vs-col>
            <vs-col vs-w="6" class="mt-2 px-1">
              <vs-input
                class="inputx w-full"
                label-placeholder="CFOP"
                v-model="produto.cfop"
              />
            </vs-col>
            <vs-col vs-w="6" class="mt-2 px-1">
              <vs-input
                class="inputx w-full"
                label-placeholder="CST"
                v-model="produto.cst"
              />
            </vs-col>
            <vs-col vs-w="6" class="mt-2 px-1">
              <vs-input
                class="inputx w-full"
                label-placeholder="NCM"
                v-model="produto.ncm"
              />
            </vs-col>
            <vs-col vs-w="6" class="mt-2 px-1">
              <vs-input
                class="inputx w-full"
                label-placeholder="Grupo Quimico"
                v-model="produto.grupo_quimico"
              />
            </vs-col>
            <vs-col vs-w="6" class="mt-2 px-1">
              <vs-input
                class="inputx w-full"
                label-placeholder="Antidoto / Tratamento"
                v-model="produto.antidoto_tratamento"
              />
            </vs-col>
            <vs-col vs-w="6" class="mt-2 px-1">
              <vs-input
                class="inputx w-full"
                label-placeholder="Acao toxica"
                v-model="produto.acao_toxica"
              />
            </vs-col>
            <vs-col vs-w="6" class="mt-2 px-1">
              <vs-input
                class="inputx w-full"
                label-placeholder="Concentracao"
                v-model="produto.concentracao"
              />
            </vs-col>
            <vs-col vs-w="6" class="mt-2 px-1">
              <vs-input
                class="inputx w-full"
                label-placeholder="Principio Ativo"
                v-model="produto.concentracao_principio_ativo"
              />
            </vs-col>
            <vs-col vs-w="6" class="mt-2 px-1">
              <vs-input
                class="inputx w-full"
                label-placeholder="Diluicao"
                v-model="produto.diluicao"
              />
            </vs-col>

            <vs-col vs-w="5" class="mt-2 px-1">
              <div class="w-full">
                <label style="color: black; font-size: 0.85rem" class="ml-1"
                  >Unid. de Medida</label
                ><br />
                <el-select
                  filterable
                  clearable
                  placeholder="Selecione..."
                  v-validate="'required'"
                  class="w-full"
                  name="undMedida"
                  v-model="produto.id_unidade_medida"
                  :popper-append-to-body="false"
                >
                  <el-option
                    v-for="unidade in unidadeMedida"
                    class="select-info"
                    :value="unidade.id"
                    :label="unidade.nome"
                    :key="unidade.id"
                  >
                  </el-option>
                </el-select>
                <vs-row>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('undMedida')"
                  >
                    {{ $validators.empty }}</span
                  >
                </vs-row>
              </div>
            </vs-col>
            <vs-col
              vs-col
              vs-w="2"
              vs-justify="center"
              vs-align="center"
              vs-type="flex"
              class="mt-2"
            >
              <div class="mt-10 p-0">
                <vs-checkbox color="success" v-model="produto.isca"
                  >ISCA</vs-checkbox
                >
              </div>
            </vs-col>
            <vs-col
              vs-w="4.5"
              vs-justify="center"
              vs-align="center"
              vs-type="flex"
              class="mt-2 px-1"
            >
              <vs-input-number
                label="Qtd. Minima"
                style="font-size: 14px; width: 70%"
                class="mt-10 p-0 m-0"
                :min="1"
                name="qtd"
                v-validate="'numeric'"
                v-model="produto.quantidade_minima"
              />
              <span class="text-danger text-sm" v-show="errors.has('qtd')">{{
                $validators.numeric
              }}</span>
            </vs-col>
            <div id="lotes_div" class="vs-con-loading__container">
              <vs-divider color="success" class="pb-0 mb-0 mt-10"
                >LOTES</vs-divider
              >

              <vs-col vs-w="12" v-for="lote in produtoLotes" :key="lote.id">
                <vx-card
                  class="card-lev overflow-hidden my-2 py-1"
                  :card-background="lote.lixeira == 1 ? 'danger' : 'success'"
                >
                  <template slot="no-body">
                    <div class="item-details px-2">
                      <div class="my-2">
                        <vs-col
                          vs-w="12"
                          vs-type="flex"
                          vs-justify="flex-start"
                          vs-align="center"
                          class=""
                          :style="
                            lote.lixeira == 1 ? 'color: white' : 'color: white'
                          "
                        >
                          <div class="w-full">
                            <span style="font-size: 18px"
                              >LOTE: <b>{{ lote.lote }}</b></span
                            >
                            <span style="font-size: 18px">
                              &nbsp;- VALIDADE:
                              <b>{{ lote.data_validade }}</b></span
                            >
                          </div>
                        </vs-col>
                      </div>
                    </div>
                  </template>
                </vx-card>
              </vs-col>

              <vs-col
                vs-w="4.5"
                vs-justify="center"
                vs-align="center"
                vs-type="flex"
                class="px-1 mt-5"
              >
                <vs-input
                  label="Lote"
                  type="text"
                  style="font-size: 14px; width: 90%"
                  class="p-0 m-0"
                  name="lote"
                  v-model="lote.lote"
                />
              </vs-col>
              <vs-col
                vs-w="4.5"
                vs-justify="center"
                vs-align="center"
                vs-type="flex"
                class="px-1 mt-5"
              >
                <vs-input
                  label="Validade"
                  type="date"
                  style="font-size: 14px; width: 90%"
                  class="p-0 m-0"
                  name="validade"
                  v-model="lote.validade"
                />
              </vs-col>
              <vs-col
                vs-w="2"
                vs-justify="center"
                vs-align="center"
                vs-type="flex"
                class="px-1 mt-5"
              >
                <vs-button
                  class="mt-10"
                  name="validade"
                  size="small"
                  type="relief"
                  color="success"
                  @click="postProdutoLotes()"
                  >Adicionar</vs-button
                >
              </vs-col>
            </div>
          </vs-row>
        </VuePerfectScrollbar>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  name: "modalEditarProduto",
  props: { produto: Object },
  data() {
    return {
      modalEditarProduto: false,
      unidadeMedida: {},
      error: false,
      lote: {},
      produtoLotes: null,
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.6,
        height: 1000,
      },
      originalData: {},
    };
  },
  async mounted() {
    // await this.getProdutoLotes();
    await this.getUnidadeMedida();
    this._beforeEditingCache = Object.assign({}, this.produto);
    this.originalData = this.produto;
  },
  methods: {
    async submitForm() {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.editarProduto();
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger",
            });
          }
        });
      });
    },
    async editarProduto() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.$vs.loading();
          try {
            const res = await this.$http.put(
              `produto/${this.produto.id}`,
              this.produto
            );
            let logData = {
              id_colaborador: localStorage.getItem("id"),
              funcao: "atualizar",
              setor: "comercial",
              ip: window.localStorage.getItem("ip"),
              texto: "Edição do produto N°" + res.id,
            };
            await this.$logger(logData);
            this.$vs.notify(this.$notify.Success);
            this.modalEditarProduto = false;
          } catch (err) {
            const error = this.$httpErrors(err);
            this.$vs.notify(error);
          } finally {
            this.$vs.loading.close();
          }
        }
      });
    },
    async exluirProduto() {
      this.$vs.loading();
      try {
        await this.$http.delete(`produto/${this.produto.id}`);
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "excluir",
        //   setor: "comercial",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: "Exclusão do produto N°" + this.produto.id,
        // };
        // await this.$logger(logData);
        this.$vs.notify(this.$notify.Success);
        this.modalEditarProduto = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
        this.$emit("update");
      }
    },
    async mudarStatusProduto() {
      this.$vs.loading();
      try {
        const res = await this.$http.put(`produto/${this.produto.id}`, {
          lixeira: !this.produto.lixeira,
        });
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Mudança do status do produto N°" + res.id,
        };
        await this.$logger(logData);
        this.$vs.notify(this.$notify.Success);
        this.modalEditarProduto = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
        this.$emit("update");
      }
    },
    cancelar() {
      Object.assign(this.produto, this._beforeEditingCache);
      this.originalData = this._beforeEditingCache = null;
      this.modalEditarProduto = false;
    },
    async getUnidadeMedida() {
      try {
        this.unidadeMedida = await this.$http.get(`unidade_medida`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getProdutoLotes() {
      await this.$vs.loading();
      try {
        this.produtoLotes = await this.$http.get(
          `produtoLote/${this.produto.id}`
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      await this.$vs.loading.close();
    },
    async postProdutoLotes() {
      await this.$vs.loading();

      try {
        await this.deactivateProdutoLotes();
        await this.$http.post(`produtoLote`, {
          id_produto: this.produto.id,
          lote: this.lote.lote,
          data_validade: this.lote.validade,
        });
        await this.getProdutoLotes();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      await this.$vs.loading.close();
    },
    async deactivateProdutoLotes() {
      try {
        await this.$http.delete(`produtoLote/${this.produto.id}`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
  },
  components: components,
};
</script>

<style lang="scss" scoped>
.scroll-cliente-editar {
  height: 72vh !important;
}
.card-lev {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-1.5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
